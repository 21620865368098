import { Carousel, Container, Row, Col } from 'react-bootstrap'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import Reviews from '../components/Reviews'

import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

function Home() {
  const position = [27.06317, -80.13708]
  const address = '11543 SE Federal Hwy, Hobe Sound, FL 33455'

  return (
    <div className='main-container'>
      <Carousel fade>
        <Carousel.Item>
          <img
            className='d-block w-50'
            src='images/entrance-min.JPEG'
            alt='First slide'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='images/exterior-min.JPG'
            alt='Second slide'
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className='d-block w-100'
            src='images/rv-storage-min.JPG'
            alt='Third slide'
          />
        </Carousel.Item>
      </Carousel>

      <h3 className='description'>
        Provides you with clean, climate controlled storage space designed for
        storing your possessions. Reclaim that precious space at home or in your
        office that has seldom used articles or items that you just can't part
        with. Distributor and sales drops are also welcome!
      </h3>

      <div className='bullets'>
        <Container fluid>
          <Row sm={2} md={4} className='g-3'>
            <Col className='bullet-item'>
              <p>∙Climate Controlled</p>
            </Col>
            <Col className='bullet-item'>
              <p>∙Controlled Access</p>
            </Col>
            <Col className='bullet-item'>
              <p>∙24/7 Secure Surveillance</p>
            </Col>
            <Col className='bullet-item'>
              <p>∙Fire and Burglar Alarmed</p>
            </Col>
            <Col className='bullet-item'>
              <p>∙Various Sizes Available</p>
            </Col>
            <Col className='bullet-item'>
              <p>∙Pest Controlled</p>
            </Col>
            <Col className='bullet-item'>
              <p>∙No Admin Fees or Security Deposit</p>
            </Col>
            <Col className='bullet-item'>
              <p>∙RV and Boat Storage Available</p>
            </Col>
          </Row>
        </Container>
      </div>
      <h2 className='description'>No Hidden Fees!!</h2>
      <Container fluid>
        <Reviews />
      </Container>

      <Container fluid className='location-container'>
        <Row xs={1} md={2}>
          <Col>
            <div className='leafletContainer align-self-center'>
              <MapContainer
                style={{ height: '100%', width: '100%' }}
                center={position}
                zoom={14}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />

                <Marker position={position}>
                  <Popup>{address}</Popup>
                </Marker>
              </MapContainer>
            </div>
          </Col>
          <Col className='address-info align-self-center'>
            <h3 className='mb-6'>Hours</h3>
            <h5 className='mb-2'>Access Hours: 7 days/week, 6:00am - 8:00pm</h5>
            <h5 className='mb-4'>
              Office Hours: Tuesday - Friday, 9:00am - 3:00pm
            </h5>
            <h3 className='mb-6'>Location</h3>
            <h5 className='mb-2'>
              Address: 11543 SE Federal Hwy, Hobe Sound, FL 33455
            </h5>
            <h6 className='mb-3'>
              We are conveniently located 1/4 mile north of Bridge Road in Hobe
              Sound, just north of McDonalds on the east side of the road.
            </h6>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home

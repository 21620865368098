import React from 'react'

const NotFound = () => {
  return (
    <div className='main-container'>
      <div className='not-found'>
        <h2 className='description'>Page Not Found</h2>
        <h4 className='description'>
          We are sorry but the page you are looking for doesn't exist.
        </h4>
      </div>
    </div>
  )
}

export default NotFound

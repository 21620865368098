import { Carousel } from 'react-bootstrap'

function Reviews() {
  return (
    <div className='main-container reviews rounded-4'>
      <h4 className='text-center'>Customer Reviews</h4>
      <Carousel variant='dark' className='reviews-carousel'>
        <Carousel.Item>
          <p className='review-name'>Sara Snow</p>
          <h5 className=' review-text'>
            Great owners and location. Way easy to set up and have had great
            customer service. The prices are very reasonable as well. Thank you,
            Secure Storage!
          </h5>
        </Carousel.Item>
        <Carousel.Item>
          <p className='review-name'>Don Clemens</p>
          <h5 className=' review-text'>
            Extremely friendly and personable. Easy access in and out, very
            convenient!
          </h5>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default Reviews

import { useRef } from 'react'
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify'

function ReviewForm() {
  const form = useRef()

  const sendFeedback = (e) => {
    e.preventDefault()

    emailjs
      // Use keys and the current reference state of the form to send email
      .sendForm(
        // Service ID
        'service_j8ozj0q',
        // Template ID
        'template_1wxehlf',
        form.current,
        // Public ID
        'npCy5bIbMLnn1Pge2'
      )
      // Notify the user of the end result
      .then(
        (result) => {
          console.log(result.text)
          toast.success('Success!! Thank you for your feedback!', {
            position: 'top-center',
          })
        },
        (error) => {
          console.log(error.text)
          toast.error(
            'There seems to have been an error submitting your review. Please try again.',
            { position: 'top-center' }
          )
        }
      )

    // Reset the form so that user input doesn't remain after submission
    e.target.reset()
  }

  return (
    <div className='main-container'>
      <h2 className='description'>Please leave us feedback!</h2>
      <h4 className='description'>
        We would love to hear about your experience with us.
      </h4>
      <div className='feedback-form'>
        <form ref={form} onSubmit={sendFeedback}>
          <label>Name</label>
          <input
            className='form-control'
            type='text'
            name='user_name'
            required
          />

          <label>Review</label>
          <textarea
            type='text'
            className='form-control'
            name='user_review'
            required
          />

          <ToastContainer />

          <div className='d-grid mx-auto mt-2'>
            <input id='submit-btn' className='btn' type='submit' value='Send' />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ReviewForm

import { Container, Button, Card, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function Services() {
  const navigate = useNavigate()

  const routeChange = () => {
    const path = '/contact'
    navigate(path)
  }

  return (
    <div className='main-container'>
      <h1 className='description'>Our Services</h1>
      <Container fluid>
        <Row xs={1} md={2}>
          <Col className='my-auto'>
            <div className='img-div'>
              <img
                src='images/interior-min.JPG'
                alt='Secure Storage Team'
                className='services-img'
              />
            </div>
          </Col>
          <Col className='my-auto'>
            <div className='description'>
              <h2>
                Are you moving? Is your home or office feeling cluttered? Every
                storage requirement is unique. We offer a variety of sizes to
                choose from in order to perfectly meet your needs.
                <br />
                <br />
                Our facility is equipped with dollies and flatbeds to assist our
                clients during loading and unloading. All units have ground
                floor access making your move in and out that much easier!
              </h2>
            </div>
          </Col>
        </Row>

        <Row xs={1} md={3} className='g-3'>
          <Col>
            <Card>
              <Card.Header>
                <strong>$57</strong>/month
              </Card.Header>
              <Card.Body>
                <Card.Title>5' x 5'</Card.Title>
                <Card.Text>Seldom used items.</Card.Text>
                <Button variant='dark' onClick={routeChange}>
                  Inquire
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <strong>$78</strong>/month
              </Card.Header>
              <Card.Body>
                <Card.Title>5' x 8.5'</Card.Title>
                <Card.Text>Seldom used items.</Card.Text>
                <Button variant='dark' onClick={routeChange}>
                  Inquire
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <strong>$86</strong>/month
              </Card.Header>
              <Card.Body>
                <Card.Title>5' x 10'</Card.Title>
                <Card.Text>One bedroom apartment.</Card.Text>
                <Button variant='dark' onClick={routeChange}>
                  Inquire
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <strong>$126</strong>/month
              </Card.Header>
              <Card.Body>
                <Card.Title>5' x 15'</Card.Title>
                <Card.Text>Two bedroom condo or small home.</Card.Text>
                <Button variant='dark' onClick={routeChange}>
                  Inquire
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <strong>$137</strong>/month
              </Card.Header>
              <Card.Body>
                <Card.Title>8.5' x 10'</Card.Title>
                <Card.Text>Two bedroom condo or small home.</Card.Text>
                <Button variant='dark' onClick={routeChange}>
                  Inquire
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <strong>$172</strong>/month
              </Card.Header>
              <Card.Body>
                <Card.Title>10' x 10'</Card.Title>
                <Card.Text>Two bedroom home with appliances.</Card.Text>
                <Button variant='dark' onClick={routeChange}>
                  Inquire
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <strong>$200</strong>/month
              </Card.Header>
              <Card.Body>
                <Card.Title>8.5' x 15'</Card.Title>
                <Card.Text>
                  Two bedroom home with appliances and small patio furniture.
                </Card.Text>
                <Button variant='dark' onClick={routeChange}>
                  Inquire
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <strong>$253</strong>/month
              </Card.Header>
              <Card.Body>
                <Card.Title>10' x 15'</Card.Title>
                <Card.Text>Perfect for three bedroom home.</Card.Text>
                <Button variant='dark' onClick={routeChange}>
                  Inquire
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <strong>$306</strong>/month
              </Card.Header>
              <Card.Body>
                <Card.Title>15' x 15'</Card.Title>
                <Card.Text>Perfect for four bedroom home.</Card.Text>
                <Button variant='dark' onClick={routeChange}>
                  Inquire
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <strong>$155</strong>/month
              </Card.Header>
              <Card.Body>
                <Card.Title>RV / Boat</Card.Title>
                <Card.Text>
                  Perfect for storing your toys while they're not in use.
                </Card.Text>
                <Button variant='dark' onClick={routeChange}>
                  Inquire
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Services

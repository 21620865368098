import { useRef } from 'react'
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify'

function Contact() {
  // Point reference toward DOM element of form
  const form = useRef()

  // Function to send email - can be referenced here https://www.emailjs.com/docs/examples/reactjs/
  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      // Use keys and the current reference state of the form to send email
      .sendForm(
        // Service ID
        process.env.REACT_APP_SERVICE_ID,
        // Template ID
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        // Public key
        process.env.REACT_APP_PUBLIC_KEY
      )
      // Notify the user of the end result
      .then(
        (result) => {
          console.log(result.text)

          toast.success(
            'Success!! A representative from Secure Storage will be in touch soon.',
            { position: 'top-center' }
          )
        },
        (error) => {
          console.log(error.text)
          toast.error(
            'There seems to have been an error submitting the form. Please try again.',
            { position: 'top-center' }
          )
        }
      )

    // Reset the form so that user input doesn't remain after submission
    e.target.reset()
  }

  return (
    <div className='main-container'>
      <h1 className='description'>Contact Us</h1>
      <div id='contact' className='contact-me mx-auto row'>
        <div className='col-md-6 text-center align-self-center'>
          <h2>Interested in what we have to offer?</h2>
          <h4 className='mx-auto'>
            Just fill out the contact form and we'll be sure to get in touch
            with you as soon as possible!
          </h4>
          <h4 className='pt-5'>Or call us at: (772)-546-5661</h4>
        </div>

        {/* User input section for email form */}
        <div className='col-md-6 py-5'>
          <form ref={form} onSubmit={sendEmail} id='contact-form'>
            <label>Name</label>
            <input
              className='form-control'
              id='name'
              type='text'
              name='user_name'
              required
            />

            <label>Subject</label>
            <select
              className='form-control'
              id='subject'
              type='text'
              name='subject'
              required
            >
              <option defaultValue></option>
              <option value="5' x 5'">5' x 5' </option>
              <option value="5' x 8.5'">5' x 8.5' </option>
              <option value="5' x 10'">5' x 10' </option>
              <option value="5' x 15'">5' x 15' </option>
              <option value="8.5' x 10'">8.5' x 10' </option>
              <option value="10' x 10'">10' x 10' </option>
              <option value="8.5' x 15'">8.5' x 15' </option>
              <option value="10' x 15'">10' x 15' </option>
              <option value="15' x 15'">15' x 15' </option>
              <option value='RV/Boat'>RV/Boat </option>
              <option value='other'>other </option>
            </select>

            <label>Email</label>
            <input
              className='form-control'
              id='email'
              type='email'
              name='user_email'
              required
            />

            <label>Phone Number</label>
            <input
              className='form-control'
              id='phone'
              type='tel'
              minlength='8'
              maxlength='10'
              // pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
              name='user_phone'
              required
            />

            <label>Message</label>
            <textarea
              className='form-control'
              id='message'
              name='message'
              required
            />

            <ToastContainer />

            <div className='d-grid mx-auto mt-2'>
              <input
                id='submit-btn'
                className='btn'
                type='submit'
                value='Send'
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact

import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='footer'>
      {/* Footer with react bootstrap container formatting - can be referenced here https://react-bootstrap.github.io/layout/grid/ */}
      <Container>
        <a
          className='footer-link link hoverColor'
          href='https://m.facebook.com/SecureStorageHS/'
          target='_blank'
          rel='noreferrer'
        >
          Facebook
        </a>
        <a
          className='footer-link link hoverColor'
          href='https://www.instagram.com/?hl=en'
          target='_blank'
          rel='noreferrer'
        >
          Instagram
        </a>
        <Link to={'/feedback'} className='footer-link link hoverColor'>
          Leave a Review
        </Link>
        <p className='copyright link'>© 2022 Secure Storage Inc.</p>
      </Container>
    </div>
  )
}

export default Footer

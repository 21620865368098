import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './pages/Home';
import About from './pages/About'
import Services from './pages/Services'
import Contact from './pages/Contact'
import Header from './components/Header'
import Footer from './components/Footer'
import Feedback from './pages/Feedback'
import NotFound from './components/NotFound'


function App() {
  return (
    <>
      <Router>
        <Header />   
        
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/feedback' element={<Feedback />} />

          <Route path='*' element={<NotFound />} />
        </Routes>

        <Footer />
      </Router>
    </>
  )
}

export default App;

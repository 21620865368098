import { Container, Col, Row } from 'react-bootstrap'

function About() {
  return (
    <div className='main-container'>
      <h1 className='description'>About Us</h1>
      <Container fluid>
        <Row xs={1} md={2} className='g-3'>
          <Col className='order-sm-0 my-auto'>
            <img
              src='images/original-building-min.jpg'
              alt='Secure Storage Team'
              className='about-img'
            />
          </Col>
          <Col className='description order-sm-1'>
            <h3>
              Secure Storage in Hobe Sound, Florida is a proud family-owned and
              operated business that has faithfully served the Treasure Coast
              community for over 25 years. In 1986, the Davino Family purchased
              the 2 acre plot of land with big dreams. Secure Storage eagerly
              opened its doors in 1996 with a mission to provide the residents
              of the Treasure Coast a place that would fulfill all of their
              storage and security needs.
            </h3>
          </Col>
        </Row>
        <Row xs={1} md={2} className='g-3'>
          <Col className='order-md-last my-auto'>
            <img
              src='images/outdoor-access-min.JPG'
              alt='Secure Storage Team'
              className='about-img'
            />
          </Col>
          <Col className='description order-md-first'>
            <h3>
              First providing parking for RVs and automobiles, the Davino family
              eventually expanded, building a 17,000 sq. ft. facility that would
              provide climate controlled storage units to customers. Founder,
              Carl Davino, was an expert in security and specialized in the lock
              & key and security system trade. Building this establishment from
              the ground up, Carl utilized this skill set to design the property
              to provide customers convenient and accessible storage with the
              highest level of security and safety available. Through the years,
              the business has been passed down through this close-knit family
              who proudly operate Secure Storage with the same passion, quality,
              and customer service that it was founded on.
            </h3>
          </Col>
        </Row>
        <Row xs={1} md={2} className='g-3'>
          <Col className='order-sm-0 my-auto'>
            <img
              src='images/units-min.JPG'
              alt='Secure Storage Team'
              className='about-img'
            />
          </Col>
          <Col className='description order-sm-1'>
            <h3>
              The Secure Storage family looks forward to meeting you and
              assisting you with all of your parking and storage needs.
            </h3>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default About

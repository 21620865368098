import { Container, Navbar, Nav } from 'react-bootstrap'
import Logo from './transparent-logo.png'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <>
      <Navbar
        collapseOnSelect
        className='navbar'
        fixed='top'
        expand='lg'
        variant='dark'
      >
        <Container fluid>
          <Navbar.Brand>
            <Link to='/'>
              <img alt='Secure Storage Inc.' src={Logo} className='logo' />
            </Link>
            <p className='slogan'>Family Owned & Operated Since 1996</p>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='ml-auto'>
              <Nav.Link className='nav-link' href='/services'>
                Services
              </Nav.Link>
              <Nav.Link className='nav-link' href='/about'>
                About
              </Nav.Link>
              <Nav.Link className='nav-link' href='/contact'>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
